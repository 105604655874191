import React from "react";
import { featuresImages } from './data';
import './features.css'



const Features = () => {
    return (
        <section id="features" className="intro max-w-[1240px] mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[30px] content-center justify-center items-center px-[20px] py-[80px] sm:px-[20px]">
            {featuresImages.map((image) => (
                <div key={image.id} className="item aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80 ">
                    <img src={image.src} alt={image.alt} className="object-fill" />
                    <a href="#"></a>
                </div>
            ))}
        </section>
    );
};

export default Features;