import React from 'react';
import Cover from '../components/pagecover/Cover';
import ContactForm from "../components/contactform/ContactForm";
import Map from "../components/map/Map";

const Contacts = () => {
    return (
        <div id="contacts">
            <Cover
                backgroundImage="/images/cover-02.jpg"
                title="Contact Us"
                description="Learn more about our journey and values"
            />

            <ContactForm />

            <Map />

        </div>
    );
};

export default Contacts;