import React from 'react';
import { useParams } from 'react-router-dom';
import { products } from '../components/menu/data';
import ThumbSlider from '../components/thumbslider/ThumbSlider'; // Ensure this path is correct

const defaultImage = 'images/cover-01.png'; // Set your default image path

const ProductPage = () => {
    const { id } = useParams();
    const product = products.find((p) => p.id === parseInt(id, 10));

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div className="product-page">
            {/* Cover Section */}
            <div className="cover" style={{ backgroundImage: `url(${product.coverImage || defaultImage})` }}>
                <div className="cover-content text-white p-8">
                    <h1 className="text-4xl font-bold mb-4">{product.name}</h1>
                    <p className="mb-4">{product.description}</p>
                </div>
            </div>

            {/* Product Details Section */}
            <div className="product-details max-w-[1240px] mx-auto">
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-[40px] py-[80px] px-[20px]">
                    <div className="max-w-full">
                        <ThumbSlider />
                    </div>
                    <div className="max-w-full">
                        <h2 className="text-3xl font-bold mb-4">{product.name}</h2>
                        <p>{product.longDescription}</p>
                        <button className="mt-4 bg-black text-white px-6 py-2 hover:text-black hover:bg-gray-900">
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
