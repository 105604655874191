import React from "react";
import { NavLink } from 'react-router-dom';
import './footer.css';
import SocialMediaIcons from '../socialmediaicons/SocialMediaIcons';

const Footer = () => {
    return (
        <section id="footer" className="sm:px-[20px]">
            <footer className="max-w-[1240px] mx-auto flex justify-between content-between gap-[30px] content-center justify-center items-center px-[20px] py-[80px] sm:px-[20px]">
                <ul className="info_links flex justify-between content-between gap-[30px]">
                    <li>
                        <NavLink className="roboto-light" to="/privacy">
                            Privacy
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="roboto-light" to="/accessibility">
                            Accessibility
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="roboto-light" to="/terms">
                            terms
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="roboto-light" to="/return">
                            Return
                        </NavLink>
                    </li>
                </ul>

                <SocialMediaIcons />
            </footer>

            <div className="copyrights flex-1">
                <p className="font-bold">© 2024, eveneiusa.</p>
            </div>

        </section>
    )
}

export default Footer;