import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MegaMenu from './MegaMenu';
import './menu.css';

function Menu({ setOpen }) {
    const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsProductDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsProductDropdownOpen(false);
    };

    const handleLinkClick = () => {
        setOpen(false);
    };

    return (
        <nav className="menu flex flex-row align-center">
            <ul className="flex flex-row items-center">
                <li>
                    <NavLink
                        to="/"
                        end
                        className={({ isActive }) => (isActive ? 'active' : '')}
                        onClick={handleLinkClick}
                    >
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/about"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                        onClick={handleLinkClick}
                    >
                        About us
                    </NavLink>
                </li>
                <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <NavLink
                        to="/products"
                        className={({ isActive }) => (isActive ? 'active' : '')}

                    >
                        Products
                    </NavLink>
                    {isProductDropdownOpen && (
                        <ul className="dropdown absolute left-0 right-0 max-w-[1200px] mx-auto rounded-md bg-white px-3 py-2 text-md font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                            <MegaMenu setOpen={setOpen} />
                        </ul>
                    )}
                </li>
                <li>
                    <NavLink
                        to="/contacts"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                        onClick={handleLinkClick}
                    >
                        Contact us
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default Menu;
