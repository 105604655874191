import React from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { products } from '../../components/menu/data';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '../slider/slider.css';

const defaultImage = 'images/default.jpg'; // Set your default image path

export default function ThumbSlider() {
    const { id } = useParams();
    const product = products.find((p) => p.id === parseInt(id, 10));

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                slidesPerView={1}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                style={{
                    width: '100%',
                    maxWidth: '300px',
                    margin: '0 auto',
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                }}
            >
                {(product.images.length > 0 ? product.images : [defaultImage]).map((imgSrc, index) => (
                    <SwiperSlide key={index}>
                        <img src={imgSrc} alt={`Slide ${index}`} className="w-full h-auto object-center object-cover" />
                    </SwiperSlide>
                ))}
            </Swiper>

        </>
    );
}
