// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./slider.css";

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { slideImages } from "./data";
import React from "react";

export default function App() {
    return (
        <section id="slider" className="mt-[80px]">
            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >
                {slideImages.map((image, i) => (
                    <SwiperSlide
                        key={`swiper_slide${i + 1}`}
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%', // Adjust height as needed
                        }}
                    >
                        {/* Optionally, you can add content inside the slide */}
                    </SwiperSlide>
                ))}

            </Swiper>
        </section>
    );
}
