import React from "react";
import './socialmedia.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faTiktok,
} from "@fortawesome/free-brands-svg-icons";

export default function SocialMediaIcons() {
    return (
        <div className="social-icons flex items-center content-center gap-x-[10px]">
            <a
                href="https://www.facebook.com/"
                className="facebook social"
            >
                <FontAwesomeIcon icon={faFacebookF}  />
            </a>
            <a
                href="https://www.instagram.com/evenei.usa?igsh=MWtlOTFsN2N4a2Y5aA=="
                className="instagram social"
            >
                <FontAwesomeIcon icon={faInstagram}  />
            </a>
            <a href="https://wwww.twitter.com" className="twitter social">
                <FontAwesomeIcon icon={faTwitter}  />
            </a>
            <a href="https://www.tiktok.com/@evenei.usa?_t=8oEeXff2tZB&_r=1" className="tiktok social">
                <FontAwesomeIcon icon={faTiktok}  />
            </a>
        </div>
    );
}
