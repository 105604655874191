
export const featuresImages = [
    { id: 1, src: 'images/features/1.jpg', alt: 'Description of image 1' },
    { id: 2, src: 'images/features/2.jpg', alt: 'Description of image 2' },
    { id: 3, src: 'images/features/3.jpg', alt: 'Description of image 3' },
    { id: 4, src: 'images/features/4.jpg', alt: 'Description of image 1' },
    { id: 5, src: 'images/features/5.jpg', alt: 'Description of image 2' },
    { id: 6, src: 'images/features/6.jpg', alt: 'Description of image 3' },
    // Add more images as needed
];

export default featuresImages;