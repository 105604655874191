import React from 'react';
import './form.css';
import { helpData } from '../../components/help/data';

const ContactsFrom = () => {
    return (

        <section id="contacts" className="max-w-[1240px] mx-auto bg-white px-[20px] py-[80px] -top-[100px] relative z-2 grid md:grid-cols-2 sm:grid-cols-1">
            <div>
                <h1 className="text-3xl font-bold mb-8">Contact Us</h1>
                <div className="flex flex-col gap-[30px]">
                    <div>
                        <h2 className="text-xl font-bold">Call to Us:</h2>
                        <p className="text-gray-500">We’re available 24/7, 7 days a week.</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-bold">Write to Us:</h2>
                        <p className="text-gray-500">Fill out our form and we will contact you within 24 hours.</p>
                        {helpData.map((data) => (
                            <div key={data.id} className="text-gray-900">

                                <a href={`mailto:${data.helpEmail}`}>
                                    <span>{ data.helpEmail }</span>
                                </a>
                            </div>
                        ))}

                    </div>
                    <div>
                        <h2 className="text-xl font-bold">Address:</h2>
                        <p className="text-gray-500">3524 Breakwater Ave, Unit 109, Hayward, CA 94545, United States</p>
                    </div>
                </div>
            </div>

            <div>
                <form action="">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                First name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 px-[15px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-0"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Last name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 px-[15px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-0"
                                />
                            </div>
                        </div>
                        <div className="col-span-full">
                            <div className="sm:col-span-3">
                                <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
                                    Subject
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="subject"
                                        name="subject"
                                        type="text"
                                        autoComplete="subject"
                                        className="block w-full rounded-md border-0 py-1.5 px-[15px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-0"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                Message
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="about"
                                    name="about"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 px-[15px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-0"
                                    defaultValue={''}
                                />
                            </div>
                        </div>


                        <div className="col-span-full">
                            <button
                                type="submit"
                                className=" block w-full bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm "
                            >
                                Send Message
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </section>
    );
};

export default ContactsFrom;